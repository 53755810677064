import React from 'react'
import templates from '@rdk/config/react-product-category-templates'
import pageHeaders from '@rdk/config/react-page-headers'

export default function ProductCategoryPage(props) {
	return (
		<>
			{pageHeaders.map((Header, key) => {
				return (
					<Header key={key} page={props.pageContext.category} />
				)
			})}
			{templates.map((Template, index) => (
				<Template key={index} {...props} />
			))}
			{!templates.length && (
				<div>No product category page templates found.</div>
			)}
		</>
	)
}