import React from 'react'
import get from 'lodash/get'
import Link from '@app/link'
import SanityImg from '@app/responsive-image-sanity'
import Head from '@app/head'
import LayoutWrapper from '@app/layout-wrapper'
import { css } from '@emotion/core'
import { renderStyles } from '@app/sanity-style-fields/css'
import settings from '@data/sanity/wcProductdeveloperSettings.json'
import Breadcrumbs from '@rdk/bread-crumbs-plugin/react/bread-crumbs'

export default function ProductCategoryPage(props) {
	let category = get(props, `pageContext.category`, {})
	let products = get(props, `pageContext.products`, [])

	return (
		<LayoutWrapper>
			<Head title={category.title} />
			<h1>{category.title}</h1>
			<Breadcrumbs pageTitle={category.title} />
			<ul css={styles.list}>
				{products.map(prod => {
					const variants = prod.variants || []
					let image = get(variants, `0.images.0.asset`)
					let slug = get(prod, `slug.current`)
					let lowestPrice
					variants.forEach(v => {
						const price = Number(v.price)
						if (!lowestPrice || price < lowestPrice){
							lowestPrice = v.price
						}
					})
					return (
						<li key={prod._id} css={styles.item}>
							<Link to={`/${slug}`} css={styles.link}>
								<div css={styles.imgContainer}>
									<SanityImg
										width={300}
										height={300}
										asset={image}
										alt={prod.title}
									/>
								</div>
								<h2 css={styles.productTitle}>{prod.title}</h2>
								{!!lowestPrice && (
									<div css={styles.pricing}>${lowestPrice}</div>
								)}
							</Link>
						</li>
					)
				})}
			</ul>
		</LayoutWrapper>
	)
}

const styles = {
	imgContainer: css`
		height: 300px;
	`,
	list: css`
		list-style-type: none;
		margin: 0;
		padding: 0;
		margin-bottom: 30px;
		@media(min-width: 900px){
			display: flex;
			> *{
				width: ${100 / 3}%;
			}
		}
	`,
	item: css`
		text-align: center;
		margin: 30px;
	`,
	link: css`
		text-decoration: none;
	`,
	productTitle: css`
		margin: 0;
		${renderStyles(settings.categoryProductTitleStyles)}
	`,
	pricing: css`
		${renderStyles(settings.categoryPricingStyles)}
	`,
}